import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { RoType } from '../interface/ro-type';
import { first, firstValueFrom } from 'rxjs';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class RoTypeService {
  private _isFromMatrix = true;


  constructor(
    private _http: HttpClient,
    private _dataService: DataService
  ) { }

  /** GetAll and find by id */
  async get(id: string) {
    const types = await this.getAll()
    return types.find(roType => roType.id === id);
  }

  async getAll(cnpj?: string): Promise<RoType[]> {
    const url = `${environment.mkgoURL}/api/v1/companies-actions/ostype`;
    const header = await firstValueFrom(this._dataService.httpOptions(cnpj || this._isFromMatrix));
    const response: any = await this._http.get(url, header).pipe(first()).toPromise();
    return (response.types as RoType[]).reverse();
  }

  async add(roType: RoType): Promise<string> {
    const url = `${environment.mkgoURL}/api/v1/companies-actions/ostype`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this._http.post(url, roType, header).pipe(first()).toPromise();
    return resp['id']
  }

  async update(roType: RoType): Promise<RoType> {
    const url = `${environment.mkgoURL}/api/v1/companies-actions/ostype/${roType.id}`;
    const apiRoType = this.complyApi(roType)
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const response: any = await this._http.put(url, JSON.stringify(apiRoType), header).pipe(first()).toPromise();
    return response
  }

  async delete(id: string): Promise<RoType> {
    const url = `${environment.mkgoURL}/api/v1/companies-actions/ostype/${id}`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    return this._http.delete(url, header).pipe(first()).toPromise();
  }

  private complyApi(roType: RoType) {
    return {
      "code": roType.code,
      "type": roType.type,
      "observation": roType.observation || " ",
      "nfseCnpj": roType.nfseCnpj || " ",
      "nfeCnpj": roType.nfeCnpj || " ",
      "operation": roType.operation || null,
      "checklistAssig": roType.checklistAssig,
      "osTypeDefault": roType.osTypeDefault
    }
  }

}

