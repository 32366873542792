import { Part } from "../interface/part";
import { ApiFloat } from "./api-float";
import { Utilities } from "./utilities";

export class PartUtilities extends Utilities {

  /**
   * Compare two parts and return true if they are diferent
   */
  static changed(part: Part, formValue: Part): boolean {
    return !this.equalString(part.description, formValue.description)
      || !this.equalString(part.code, formValue.code)
      || !this.equalString(part.brand, formValue.brand)
      || !this.equalNumberWithDefault(part.purchaseValue, formValue.purchaseValue, 0)
      || !this.equalNumberWithDefault(part.saleValue, formValue.saleValue, 0)
      || (part.group && part.group.length ? part.group[0] != formValue.groupObject._id : false)
      || (part.subGroup && part.subGroup.length ? part.subGroup[0] != formValue.subgroupObject._id : false)
      || (part.application && part.application.length ? part.application[0] != formValue.applicationObject._id : false)
      || part.EANcode != formValue.EANcode
      || part.unitOfMeasurements != formValue.unitOfMeasurements
      || part.grossWeight != formValue.grossWeight
      || part.liquidWeight != formValue.liquidWeight
      || part.useIn != formValue.useIn
      || part.NCM != formValue.NCM
      || part.CEST != Number(formValue.CEST)
      || part.cost != formValue.cost
      || part.averageCost != formValue.averageCost
      || part.allocation != formValue.allocation
      || part.fabricCode != formValue.fabricCode
      || part.availableStock != formValue.availableStock
      || part.stockMin != formValue.stockMin
      || part.requestedStock != formValue.requestedStock
      || part.inOrderStock != formValue.inOrderStock
      || part.origin != formValue.origin
      || part.ANP != formValue.ANP
      || part.readjustmentPercentage != formValue.readjustmentPercentage
      || part.ICMS != formValue.ICMS
      || part.PIS != formValue.PIS
      || part.COFINS != formValue.COFINS
      || part.superSimples != formValue.superSimples
      || part.IPI != formValue.IPI
      || part.ICMS_ST != formValue.ICMS_ST
      || JSON.stringify(part.itemRelated) != JSON.stringify(formValue.itemRelated)
  }

  static complyApp(obj: any): Part {
    let part: Part = obj;
    part.description = obj.description || '';
    part.saleValue = obj.saleValue || 0;
    part.EANcode = obj.CodeEAN;
    part.unitOfMeasurements = obj.unitMeasure;
    part.readjustmentPercentage = obj.salePricePercentage;
    part.allocation = obj.location
    part.itemRelated = obj.itemRelated || [];

    part['cost'] = Number(obj.cost) || 0;
    part['stock'] = Number(obj.stock) || 0;
    part['availableStock'] = Number(obj.availableStock) || 0;
    part['requestedStock'] = Number(obj.orderStock) || 0;
    part['inOrderStock'] = Number(obj.osStock) || 0;
    part['stockMin'] = Number(obj.minStock) || 0;
    part['initStock'] = Number(obj.initStock) || 0;

    part.ICMS = {
      CST: obj.ICMS ? obj.ICMS.CST : undefined,
      Aliquot: obj.ICMS ? obj.ICMS.Aliquot : undefined,
      Message: obj.ICMS ? obj.ICMS.Message : undefined,
      CFOP: obj.ICMS ? obj.ICMS.CFOP : undefined,
      CFOP_I: obj.ICMS ? obj.ICMS.CFOP_I : undefined,
      readjustment: obj.ICMS ? obj.ICMS.readjustment : undefined
    }
    part.PIS = {
      CST: obj.PIS && obj.PIS.CST ? (obj.PIS.CST || '').padStart(2, '0') : undefined,
      Aliquot: obj.PIS ? obj.PIS.Aliquot : undefined,
      Message: obj.PIS ? obj.PIS.Message : undefined,
    }
    part.COFINS = {
      CST: obj.COFINS && obj.COFINS.CST ? (obj.COFINS.CST || '').padStart(2, '0') : undefined,
      Aliquot: obj.COFINS ? obj.COFINS.Aliquot : undefined,
      Message: obj.COFINS ? obj.COFINS.Message : undefined,
    }
    part.ICMS_ST = {
      mva: obj.ICMS_ST ? obj.ICMS_ST.mva : undefined,
      Aliquot: obj.ICMS_ST ? obj.ICMS_ST.Aliquot : undefined
    }
    part['IPI'] = { CST: obj.IPI }

    if (part.purchaseValue === undefined) {
      part.purchaseValue = null;
    }
    return part;
  }

  static complyAPI(part: Part): any {
    let apiPart = { ...part } as any;
    apiPart['useIn'] = part.useIn;
    apiPart['description'] = part.description;
    apiPart['saleValue'] = ApiFloat(apiPart.saleValue) || 0;

    apiPart['CodeEAN'] = part.EANcode || "SEM GTIN";
    delete apiPart.EANcode;

    apiPart['unitMeasure'] = part.unitOfMeasurements;
    delete apiPart.unitOfMeasurements;

    apiPart['salePricePercentage'] = part.readjustmentPercentage;
    delete apiPart.readjustmentPercentage;

    apiPart['NCM'] = Number(part.NCM ? part.NCM.toString() : "0");

    apiPart['location'] = part.allocation
    delete apiPart.allocation

    apiPart['cost'] = ApiFloat(part.cost || 0, 3);
    apiPart['stock'] = ApiFloat(part.stock || 0, 3);
    apiPart['availableStock'] = ApiFloat(part.availableStock || 0, 3);
    apiPart['orderStock'] = ApiFloat(part.requestedStock || 0, 3);
    apiPart['osStock'] = ApiFloat(part.inOrderStock || 0, 3);
    apiPart['minStock'] = ApiFloat(part.stockMin || 0, 3);
    apiPart['initStock'] = ApiFloat(part.initStock || 0, 3);

    if (part.CEST) {
      apiPart['CEST'] = Number(part.CEST.toString().replace(/\./g, ''));
    }

    if (part.IPI && part.IPI.CST){
      apiPart['IPI'] = part.IPI.CST;
    }

    if (part.parts) {
      // similars
      apiPart['parts'] = part.parts.filter(value => Boolean(value)).map(item => {
        if(typeof item == 'string'){
          return item;
        } else {
          return item.id;
        }
      })
    }

    for (const tax of ['ICMS', 'PIS', 'COFINS', 'IPI']) {
      if (!part[tax] || !part[tax].CST) {
        delete apiPart[tax];
      }
    }

    if (part.groupObject) {
      apiPart["group"] = [part.groupObject._id];
      delete apiPart['groupObject'];
    }
    if (part.subgroupObject) {
      apiPart["subGroup"] = [part.subgroupObject._id];
      delete apiPart['subgroupObject'];
    }
    if (part.applicationObject) {
      apiPart["application"] = [part.applicationObject._id];
      delete apiPart['applicationObject'];
    }

    return Utilities.removeNull(apiPart);
  }

}
