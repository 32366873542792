import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Part } from '../interface/part';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { PartUtilities } from '../class/part-utilities';
import { ApiPaginatedQueryParams, ApiPaginatedResponse, PaginationService } from './pagination.service';
import { Observable, firstValueFrom, of } from 'rxjs';
import { DataService } from './data.service';
import { API_ERRORS } from '../shared/lists/errors';
import { LOG_REPORTER_KEYS, LogReporterService } from './log-reporter.service';
import { ObjectId } from '../shared/type-aliases/object-id';

@Injectable({
  providedIn: 'root'
})
export class PartService {
  private _isFromMatrix = true;
  parts: Part[] = [];

  constructor(
    private http: HttpClient,
    private _dataService: DataService,
    private _logReporter: LogReporterService
  ) {
  }

  async get(id: string, cnpj?: string): Promise<Part> {
    const url = `${environment.mkgoURL}/api/v1/parts/${id}`;
    const header = await firstValueFrom(this._dataService.httpOptions(cnpj || this._isFromMatrix));
    const response: any = await this.http
      .get(url, header)
      .pipe(first())
      .toPromise();
    if (!response.parts.length) {
      throw new Error(`Part with id: ${id} has not found.`);
    }
    const apiPart = response.parts[0];
    const part = PartUtilities.complyApp(apiPart);
    return part;
  }

  async getAll(): Promise<Part[]> {
    const url = `${environment.mkgoURL}/api/v1/parts`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const response: any = await this.http
      .get(url, header)
      .pipe(first())
      .toPromise();
    (response["parts"] as Part[]).map(PartUtilities.complyApp);
    this.parts = (response["parts"] as Part[]).reverse();
    return this.parts;
  }

  async getAllByCondition(condition: 1 | 2 | 3): Promise<Part[]> {
    const url = `${environment.mkgoURL}/api/v1/parts/find/with/conditions?stockCondition=${condition}`
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const response = await firstValueFrom(this.http.get(url, header).pipe(first()));
    let parts: Part[];
    if (Array.isArray(response)) {
      parts = response.map(part => PartUtilities.complyApp(part));
    }
    return parts;
  }


  getInactiveParts(): Observable<{ parts: Part[] }> {
    const url = `${environment.mkgoURL}/api/v1/parts/inactives`;
    return this._dataService.httpOptions(this._isFromMatrix).pipe(
      switchMap(options => 
        this.http.get<{ parts: Part[] }>(url, options).pipe(
          first(),
          catchError((err) => {
            console.error(err)
            return of({ parts: [] })
          })
        ),
      ),
    )
  }
  
  getPaginated(apiParams: ApiPaginatedQueryParams, cnpj?: string): Observable<ApiPaginatedResponse<Part>> {
    const url = `${environment.mkgoURL}/api/v1/parts/pages`;
    let params = PaginationService.getParams(apiParams);
    return this._dataService.httpOptions(cnpj || this._isFromMatrix, params).pipe(
      switchMap(options => this.http.get<ApiPaginatedResponse<Part>>(url, options)),
      first(),
      catchError(err => {
        if (err.error.error === API_ERRORS.notFound) {
          return of(this._notFoundErrorDataToReturn())
        }
      }),
      switchMap(resp => {
        // the API return 100 docs by defult (even if all=1), so if we want all docs we must call it again with param `limit`
        if (apiParams.all && resp.hasNextPage) {
          apiParams.limit = resp.totalDocs;
          params = PaginationService.getParams(apiParams);
          return this._dataService.httpOptions(this._isFromMatrix, params).pipe(
            switchMap(options2 => this.http.get<ApiPaginatedResponse<Part>>(url, options2)),
            catchError(err => {
              if (err.error.error === API_ERRORS.notFound) {
                return of(this._notFoundErrorDataToReturn())
              } else {
                throw err;
              }
            }),
            first(),
          )
        } else {
          return of(resp)
        }
      }),
    );
  }

  private _notFoundErrorDataToReturn() {
    return {
      docs: [],
      hasNextPage: false,
      hasPrevPage: false,
      limit: 0,
      nextPage: null,
      page: 0,
      pagingCounter: 0,
      prevPage: null,
      totalDocs: 0,
      totalPages: 0
    }
  }

  async addOrUpdate(part: Part, caller: string): Promise<string> {
    const txtLogger = this._logReporter.textLogger(`${caller} => PartService.addOrUpdate(${part.id})`, LOG_REPORTER_KEYS.STOCK);
    const errLogger = this._logReporter.errorLogger(`${caller} => PartService.addOrUpdate()`, LOG_REPORTER_KEYS.STOCK);
    try {
      const apipart = PartUtilities.complyAPI(part);
      const logInfo = { 
        code: part.code,
        partId: part.id,
        stock: part.stock,
        requestedStock: part.requestedStock,
        inOrderStock: part.inOrderStock,
        availableStock: part.availableStock
      };
      const url = `${environment.mkgoURL}/api/v1/parts`;
      const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
      const { id } = await firstValueFrom(this.http.post<{ id: ObjectId }>(url, apipart, header));
      txtLogger(JSON.stringify({ depois: logInfo }));
      return id;
    } catch (error) {
      errLogger(error)
      throw error
    }
  }

  async addOrUpdateMany(parts: Part[]) {
    let part;
    let promises: Promise<any>[] = [];
    for (let i = 0; i < parts.length; i++) {
      part = parts[i];
      promises.push(this.addOrUpdate(part, "PartService.addOrUpdateMany()"));
    }
    await Promise.all(promises);
  }

  /*async delete(id: string): Promise<Part> {
    const url = `${environment.mkgoURL}/api/v1/parts/${id}`;
    const header = await firstValueFrom(this.dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http
      .delete(url, header)
      .pipe(first())
      .toPromise();
    return resp;
  }*/
  async delete(id: string): Promise<Part> {
    const url = `${environment.mkgoURL}/api/v1/parts/${id}/disable`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const resp: any = await this.http
      .put(url, {}, header)
      .pipe(first())
      .toPromise();
    return resp;
  }

  async checkUse(id: string) {
    const url = `${environment.mkgoURL}/api/v1/parts/${id}/check/in/use`;
    const header = await firstValueFrom(this._dataService.httpOptions(this._isFromMatrix));
    const response: any = await this.http
      .get(url, header)
      .pipe(first())
      .toPromise();
    return response;
  }

  findByFragaId(idFraga: string): Observable<Part | undefined> {
    if (!idFraga) {
      return;
    }
    const url = `${environment.mkgoURL}/api/v1/parts/id-fraga/${idFraga}`;
    return this._dataService.httpOptions(this._isFromMatrix).pipe(
      switchMap(options => this.http.get<{ parts: Part[] }>(url, options)),
      map(resp => resp.parts),
      map(arr => !!arr.length ? arr[0] : undefined)
    )
  }
}